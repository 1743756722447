import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faWhatsapp,  } from '@fortawesome/free-brands-svg-icons'

import React from 'react'
import { Container } from 'react-bootstrap'

import './footer.scss'

const mensajePredeterminado = 'Buen día Macte Nova Tech\n\nConseguí su contacto en su página WEB.\nEstoy interesado en cotizar un proyecto con ustedes.'

const redes = [
    {
        icon: faFacebookF,
        url: 'https://www.facebook.com/mactenova',
        color: 'blue'
    },
    {
        icon: faWhatsapp,
        url: `https://wa.me/526626885504/?text=${encodeURI(mensajePredeterminado)}`,
        color: 'green'
    },
    {
        icon: faInstagram,
        url: 'https://www.instagram.com/mactenova/',
        color: 'indigo'
    },
    {
        icon: faLinkedinIn,
        url: 'https://www.linkedin.com/company/mactenovatech?originalSubdomain=mx',
        color: 'blue'
    }
]


export const footer = () => {
    return (
        <Container className="footer mt-3 p-5 text-center">
            <h2 className="font-weight-light">¡Síguenos en nuestras redes sociales!</h2>
            {
                redes.map(({icon, url, color}, idx) => 
                    <a key={idx} href={url} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="footer__icon" size="2x" color={ color } icon={ icon } />
                    </a>
                )
            }
        </Container>
    )
}

export default footer