import PropTypes from "prop-types"
import React, { useEffect } from "react"
// Components
import { Navbar, Nav, Container } from 'react-bootstrap';

import './header.scss'

const Header = () => {
  useEffect(() => {
    cambiarBg()
    window.addEventListener('scroll', cambiarBg)

  }, [])

  const cambiarBg = () => {
    const header =  document.querySelector('.header__section')

    if ( window.scrollY > 50 ) {
      header.classList.add('header__section--scroll')
    } else if (window.scrollY < 50) {
        header.classList.remove('header__section--scroll')
    }
  }

  const navigate = (section) => {
    const ele = document.querySelector(section)
    
    if (ele === null) return


    const offset = ele.offsetTop
    
    window.scrollTo(0, offset)

  }

  const links = [
    {
      section: '.hero',
      title: 'Principal'
    },
    {
      section: '.nosotros',
      title: 'Nosotros'
    },
    {
      section: '.contacto',
      title: 'Contacto'
    },
    {
      section: '.footer',
      title: 'Síguenos'
    }
  ]

  return (
  <header className="header">
    <Container>
      <Navbar  className="header__section" expand="lg" fixed="top" >
        <Navbar.Brand href="#home">
          <div className="header__logo p-5"></div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {
              links.map( ({ section, title }, idx) => (
                <Nav.Link key={idx} onClick={() => navigate(section)} > { title } </Nav.Link>
              ))
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
